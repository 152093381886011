<template>
  <form class="card" @submit.prevent>
    <div class="card-body card-body-lg">
      <h2>Account Details</h2>

      <validation-provider
        tag="div"
        class="mb-3"
        name="email"
        rules="required"
      >
        <label>First Name</label>
        <c-input type="text" v-model="stateFirstName" />
      </validation-provider>

      <div class="mb-3">
        <label>Last Name</label>
        <c-input type="text" v-model="stateLastName" />
      </div>

      <validation-provider
        tag="div"
        class="mb-3"
        name="email"
        rules="required|email"
      >
        <label>Email Address</label>
        <c-input type="text" v-model="stateEmail" />
        <c-help-block />
        <p class="text-info mt-1" v-if="stateNewEmail">
          Email change pending for {{ stateNewEmail }}
        </p>
      </validation-provider>

      <div class="mb-3">
        <label>Mobile Number</label>
        <c-input type="text" placeholder="Optional" v-model="stateMobile" />
      </div>

      <div class="mb-3">
          <span class="control-label">Role</span>
          <role-select v-model="user.role"/>
      </div>

      <validation-provider
        v-if="user.role && user.role.name !== 'admin'"
        class="mb-3"
        tag="div"
        name="media owner"
        rules="required"
      >
        <label>Media Owner</label>
        <media-owner-select v-model="stateMediaOwnerId"/>
        <c-help-block />
      </validation-provider>

      <p class="text-muted small" v-if="user.last_login_at">
        <strong>Last login:</strong> {{ user.last_login_at | date({ format: 'DD/MM/YYYY [at] h:mm a' }) }} from {{ user.last_login_ip }}
      </p>

      <div class="row mb-3">
        <div class="col">
          <span class="control-label">Status</span>
        </div>
        <div class="col-auto">
          <span class="status" :class="statusClass">{{ user.status }}</span>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <c-button
            v-if="user.status === 'enabled' || user.status === 'pending'"
            variant=""
            class="btn-outline-danger"
            @click="handleToggleStatus"
          >
            Disable User
          </c-button>
          <c-button
            v-else
            variant=""
            class="btn-outline-success"
            @click="handleToggleStatus"
          >
            Enable User
          </c-button>
        </div>
        <c-button
            v-if="user.status === 'pending'"
            variant="info"
            class="col-auto"
            @click="handleResendEmail"
          >
            Resend Email
          </c-button>

        <div class="col-auto">
          <c-button
            type="submit"
            variant="primary"
            @click="save"
            validate
          >
            Save
          </c-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/users'
import { call } from 'vuex-pathify'
import { toast } from '@chameleon/chameleon-vue'
import MediaOwnerSelect from '@/components/media-owners/MediaOwnerSelect'
import { http } from '@/utils'
import RoleSelect from '@/components/forms/SelectRole'

export default {
  mixins: [stateMapper],

  components: {
    RoleSelect,
    MediaOwnerSelect
  },

  data () {
    return {
      statusOptions: [
        {
          value: 'pending',
          label: 'Pending'
        },
        {
          value: 'enabled',
          label: 'Enabled'
        },
        {
          value: 'disabled',
          label: 'Disabled'
        }
      ]
    }
  },

  computed: {
    ...helperComputed,

    statusClass () {
      if (this.stateStatus === 'enabled') {
        return 'status-success'
      } else if (this.stateStatus === 'disabled') {
        return 'status-danger'
      } else {
        return 'status-info'
      }
    },

    statusSelected: {
      get () {
        return this.statusOptions.find(option => option.value === this.user.status)
      },

      set (option) {
        this.stateStatus = option ? option.value : this.stateStatus[0]
      }
    },

    editingOwnAccount () {
      return this.user.id === this.$user.id
    }
  },

  methods: {
    ...helperMethods,
    ...call('users', ['updateUser', 'toggleStatus']),
    authorize: call('auth/authorize'),
    deleteProfileImage: call('users/deleteProfileImage'),

    async save () {
      await this.updateUser({
        id: this.user.id,
        payload: {
          form: JSON.stringify({
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            mobile: this.user.mobile,
            email: this.user.email,
            role: this.user.role,
            media_owner_id: this.user.media_owner_id
          })
        }
      })
      if (this.editingOwnAccount) {
        this.authorize()
      }
      toast('Successfully updated user', 'success')
    },

    handleToggleStatus () {
      this.toggleStatus(this.user.id)
    },
    handleResendEmail () {
      return http
        .get(`user/${this.user.id}/resend-notification`)
    }
  }
}
</script>
